<template>
  <div>
    <el-row>
      <el-col :span="1">
        <el-select v-model="ctp_account" placeholder="请选择" style="width: 200px">
          <el-option
            v-for="item in account_list"
            :key="item.id"
            :label="item.nickname"
            :value="item.ctp_account">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="warning-row" style="background-color:#FFFFFF;">
        蓝色字体为非当日数据
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <FundList :items="fund_data"/>
      </el-col>
      <el-col :span="12">
        <EntrustList :items="entrust_data"/>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <OrderList :items="order_data"/>
      </el-col>
      <el-col :span="12">
        <PositionList :items="position_data"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import OrderList from '@/components/OrderList.vue'
import FundList from '@/components/FundList.vue'
import PositionList from '@/components/PositionList.vue'
import EntrustList from '@/components/EntrustList.vue'
import request from "../utils/request.js";

export default {
  name: 'Home',
  components: {
    OrderList,
    FundList,
    PositionList,
    EntrustList
  },
  data: function () {
    return {
      account_list: [],
      ctp_account: '187694',
      url: process.env.VUE_APP_WS_API,
      heartbeatInterval: null,
      fund_data: [],
      position_data: [],
      order_data: [],
      entrust_data: []
    }
  },
  watch: {
    ctp_account: function(val) {
      console.log(val)
      this.webSocket.close()
      this.fund_data = []
      this.position_data = []
      this.order_data = []
      this.entrust_data = []
      setTimeout(this.initSocket, 500)
    }
  },
  methods: {
    async getAllAccount() {
      const res = await request.get('/snapshot', {})
      this.account_list = res.data
      this.ctp_account = '187694'
    },
    initSocket() {
      this.connect()
      this.webSocket.onopen = this.webSocketOnOpen
      this.webSocket.onclose = this.webSocketOnClose
      this.webSocket.onmessage = this.webSocketOnMessage
      this.webSocket.onerror = this.webSocketOnError
    },
    connect() {
      let url = `${this.url}/${this.ctp_account}`
      this.webSocket = new WebSocket(url)
    },
    // 建立连接成功后的状态
    webSocketOnOpen() {
      console.log('websocket连接成功');
      this.heartbeatInterval = setInterval(() => {
        this.webSocket.send('ping')
      }, 10000)
    },
    // 获取到后台消息的事件，操作数据的代码在onmessage中书写
    webSocketOnMessage(res) {
      if (res.data === 'pong') {
        return
      }
      // res就是后台实时传过来的数据
      let obj = JSON.parse(res.data)
      if (obj.fund) {
        if (Array.isArray(obj.fund)) {
          // this.fund_data = this._.concat(this.fund_data, obj.fund)
          // this.fund_data = this._.uniqBy(this.fund_data, 'id')
          this.fund_data = obj.fund
        } else {
          let index = this._.findIndex(this.fund_data, {'id': obj.fund.id})
          if (index !== -1) {
            this.$set(this.fund_data, index, obj.position)
          } else {
            this.fund_data.unshift(obj.fund)
          }
        }
      }
      if (obj.position) {
        if (Array.isArray(obj.position)) {
          // this.position_data = this._.concat(this.position_data, obj.position)
          // this.position_data = this._.uniqBy(this.position_data, 'id')
          this.position_data = obj.position
        } else {
          let index = this._.findIndex(this.position_data, {'id': obj.position.id})
          if (index !== -1) {
            this.$set(this.position_data, index, obj.position)
          } else {
            this.position_data.unshift(obj.position)
          }
        }
      }
      if (obj.order) {
        if (Array.isArray(obj.order)) {
          this.order_data = this._.concat(this.order_data, obj.order)
          this.order_data = this._.uniqBy(this.order_data, 'id')
        } else {
          let index = this._.findIndex(this.order_data, {'id': obj.order.id})
          if (index !== -1) {
            this.$set(this.order_data, index, obj.order)
          } else {
            this.order_data.unshift(obj.order)
          }
        }
      }
      if (obj.entrust) {
        if (Array.isArray(obj.entrust)) {
          this.entrust_data = this._.concat(this.entrust_data, obj.entrust)
          this.entrust_data = this._.uniqBy(this.entrust_data, 'id')
        } else {
          let index = this._.findIndex(this.entrust_data, {'id': obj.entrust.id})
          if (index !== -1) {
            this.$set(this.entrust_data, index, obj.entrust)
          } else {
            this.entrust_data.unshift(obj.entrust)
          }
        }
      }
        //给后台发送数据
    },
    // 关闭连接
    webSocketOnClose() {
      clearInterval(this.heartbeatInterval)
      this.webSocket.close()
      console.log('websocket连接已关闭')
    },
    //连接失败的事件
    webSocketOnError(res) {
      clearInterval(this.heartbeatInterval)
      console.log('websocket连接失败')
      // 打印失败的数据
      console.log(res)
      this.connect()
    }
  },
  async created() {
    // 页面打开就建立连接，根据业务需要
    await this.getAllAccount()
    this.initSocket()
  },
  destroyed() {
    // 页面销毁关闭连接
    this.webSocket.close()
  },
}
</script>
