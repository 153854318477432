<template>
  <el-card shadow="hover" header="资金" class="box-card">
    <el-table
      :data="items"
      height="400"
      border>
      <el-table-column
        prop="account"
        label="账号"
        width="180">
      </el-table-column>
      <el-table-column
        prop="balance"
        label="余额"
        width="180">
      </el-table-column>
      <el-table-column
        prop="frozen"
        label="冻结"
        width="180">
      </el-table-column>
      <el-table-column
        prop="available"
        label="可用"
        width="180">
      </el-table-column>
      <el-table-column
        prop="gateway_name"
        label="接口"
        width="180">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FundList',
  props: {
    items: Array
  },
  data: function () {
    return {
      data: []
    }
  },
  watch: {
    },
  created() {
    
  },
  methods: {
  }
}
</script>

<style scoped>
  .box-card {
    height: 480px;
  }
</style>
