<template>
  <el-card shadow="hover" header="成交" class="box-card">
    <el-table
      :data="items"
      height="400"
      border
      :row-class-name="tableRowClassName">
      <el-table-column
        prop="symbol"
        label="代码"
        width="100">
      </el-table-column>
      <el-table-column
        prop="direction"
        label="方向"
        width="80">
      </el-table-column>
      <el-table-column
        prop="offset"
        label="开平"
        width="80">
      </el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="80">
      </el-table-column>
      <el-table-column
        prop="volume"
        label="数量"
        width="50">
      </el-table-column>
      <el-table-column
        prop="datetime"
        label="时间"
        width="180"
        :formatter="datetimeFormatter">
        </el-table-column>
        <el-table-column
        prop="account"
        label="账号"
        width="100">
      </el-table-column>
      <el-table-column
        prop="tradeid"
        label="成交号"
        width="80">
      </el-table-column>
      <el-table-column
        prop="orderid"
        label="委托号"
        width="130">
      </el-table-column>
      <el-table-column
        prop="exchange"
        label="交易所"
        width="80">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
export default {
  name: 'OrderList',
  props: {
    items: Array
  },
  data: function () {
    return {
      data: []
    }
  },
  watch: {
    },
  created() {
    
  },
  methods: {
    datetimeFormatter(row,column) {
      let date = row[column.property]
      if (date == undefined) {
        return ''
      }
      return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss")
    },
    tableRowClassName({row}) {
      let date = row.datetime
      if (!this.isToday(date)) {
        return 'warning-row'
      }
      return ''
    },
    isToday(timestamp) {
      let now = moment()
      return moment(timestamp * 1000).isSame(now, 'day')
    }
  }
}
</script>

<style>
  .box-card {
    height: 480px;
  }
  .warning-row {
    background-color: #f5f7fa;
    color: blue;
  }

  .success-row {
    background-color: #f0f9eb;
  }
</style>
