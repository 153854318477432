<template>
  <el-card shadow="hover" header="持仓" class="box-card">
    <el-table
      :data="items"
      height="400"
      border>
      <el-table-column
        prop="symbol"
        label="代码"
        width="80">
      </el-table-column>
      <el-table-column
        prop="direction"
        label="方向"
        width="80">
      </el-table-column>
      <el-table-column
        prop="volume"
        label="数量"
        width="110">
      </el-table-column>
      <el-table-column
        prop="yd_volume"
        label="昨仓"
        width="110">
      </el-table-column>
      <el-table-column
        prop="frozen"
        label="冻结"
        width="110">
      </el-table-column>
      <el-table-column
        prop="price"
        label="均价"
        width="130">
      </el-table-column>
      <el-table-column
        prop="pnl"
        label="盈亏"
        width="130">
      </el-table-column>
      <el-table-column
        prop="gateway_name"
        label="接口"
        width="80">
      </el-table-column>
      <el-table-column
        prop="exchange"
        label="交易所"
        width="80">
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PositionList',
  props: {
    items: Array
  },
  data: function () {
    return {
      data: []
    }
  },
  watch: {
    },
  created() {
    
  },
  methods: {
  }
}
</script>

<style scoped>
  .box-card {
    height: 480px;
  }
</style>
